import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getNavigateUrl,
  getUrlSearchParams,
} from "../../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import { OrderAction } from "../../../../../store/action";
import {
  HomeBanner1,
  HomeBanner10,
  HomeBanner2,
  HomeBanner3,
  HomeBanner4,
  HomeBanner5,
  HomeBanner6,
  HomeBanner7,
  HomeBanner8,
  HomeBanner9,
  MainSliderIcon1,
  MainSliderIcon2,
  MainSliderIcon3,
  MainSliderIcon4,
  MainSliderIcon5,
} from "../../../../../constants/ImageConstants";
import VehicleSelector from "../../../../../components/common/vehicleSelector";
import { SERVICE_QUOTE, SHOW_HIDE_LOADER } from "../../../../../store/types";

import { checkValidMobile } from "../../../../../utils/validations";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Banner = (props) => {
  const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  const navigate = useNavigate();
  const numberInput = useRef(null);
  const {
    vehicleData,
    cityData,
    userData,
    createServiceQuote,
    serviceBookingData,
    customDispatch,
  } = props;
  const { brand, model, variant } = vehicleData;
  const { is_user_logged_in, user_profile } = userData;
  const { selectedCity } = cityData;
  const [homeMobile, setMobile] = useState(user_profile.phone || "");
  const [searchParams] = useSearchParams();
  const customParams = getUrlSearchParams(searchParams);

  const updateMobileNum = (e) => {
    let num = e.target.value;
    // if value is not blank, then test the regex
    if (num !== "") {
      setMobile(num);
    } else {
      setMobile(null);
    }
  };
  const makeVehicleChange = () => {
    document.getElementById("custom-vehicle-selector-brand").click();
  };
  const serviceQuoteCall = () => {
    if (!variant) {
      makeVehicleChange();
      return false;
    }
    if (!checkValidMobile(homeMobile)) {
      numberInput.current.focus();
      alert("Mobile Number is not valid");
      return false;
    }
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const apiParam = {
      task: "getServiceQuote",
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      mobile: homeMobile,
      city: cityData.selectedCity,
      source: "Home Page",
    };
    if (customParams) {
      let sourceName = "";
      let sourceId = "";
      let medium = "";
      customParams.forEach((element) => {
        if (element.name === "utm_source") {
          sourceName = element.value;
        }
        if (element.name === "utm_medium") {
          medium = element.value;
        }
        if (element.name === "utm_campaign") {
          sourceId = element.value;
        }
      });
      if (sourceId) {
        apiParam.source += `-${sourceId}`;
      }
      if (sourceName) {
        apiParam.source += `-${sourceName}`;
      }
      if (medium) {
        apiParam.source += `(${medium})`;
      }
    }
    createServiceQuote(apiParam);
  };
  useEffect(() => {
    if (serviceBookingData.serviceQuoteResponse !== null) {
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: SERVICE_QUOTE, payload: null });
      navigate(getNavigateUrl("/services/periodic-car-services"));
    }
  }, [serviceBookingData, navigate, customDispatch, cityData]);

  const banners = [
    'https://storage.googleapis.com/vc-wave/website-media/site_diwali_prime.png',
    'https://storage.googleapis.com/vc-wave/website-media/diwali_dent_paint_site.png',
    'https://storage.googleapis.com/vc-wave/website-media/prime_50%25_off_site.png',
    "https://storage.googleapis.com/vc-wave/website-media/doorstep-banner1.png",
    "https://storage.googleapis.com/vc-wave/website-media/car_service.png",
    "https://storage.googleapis.com/vc-wave/website-media/oil-website.jpg",
    "https://storage.googleapis.com/vc-wave/website-media/vc-prime-web-banner.png",
    "https://vehiclecare.in/vendor/service_offers/car-claim-home-banner.jpg",
    "https://storage.googleapis.com/vc-wave/website-media/mobikwik-banner-250.jpg",
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="auto-container">
            <div className="content-boxed">
              <div className="">
                <div
                  className="icon-layer-one"
                  style={{ backgroundImage: `url(${MainSliderIcon1})` }}
                ></div>
                <div
                  className="icon-layer-two"
                  style={{ backgroundImage: `url(${MainSliderIcon2})` }}
                ></div>
                <div
                  className="icon-layer-three"
                  style={{ backgroundImage: `url(${MainSliderIcon3})` }}
                ></div>
                <div
                  className="icon-layer-four"
                  style={{ backgroundImage: `url(${MainSliderIcon4})` }}
                ></div>
                <div
                  className="icon-layer-five"
                  style={{
                    backgroundImage: `url(${MainSliderIcon5})`,
                    display: `none`,
                  }}
                ></div>

                <div className="row clearfix">
                  <div
                    className="image-column col-lg-8 col-md-12 col-sm-12 mb-0 d-md-none"
                    id="homeBannerSplide"
                  >
                    <div className="inner-column">
                      <div className="image">
                        <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                            drag: "free",
                          }}
                          hasTrack={true}
                        >
                          <SplideSlide>
                            <img src={HomeBanner7} alt="250 Cashback" fluid />
                          </SplideSlide>
                          <SplideSlide>
                            <img src={HomeBanner8} alt="150 Cashback" fluid />
                          </SplideSlide>

                          <SplideSlide>
                            <img
                              src={HomeBanner1}
                              alt="Holi offer"
                              loading=" lazy"
                              fluid
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <img
                              src={HomeBanner2}
                              alt="Periodic car service for peak performance!"
                              fluid
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <img
                              src={HomeBanner3}
                              alt="Your claim journey partner"
                              fluid
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <img
                              src={HomeBanner4}
                              alt="Revitalize your ride with primecare car service"
                              fluid
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <Link to={"/lubricants"}>
                              <img
                                src={HomeBanner5}
                                alt="Driving Lubricants Innovation for all types of engines"
                                fluid
                              />
                            </Link>
                          </SplideSlide>
                          <SplideSlide>
                            <img src={HomeBanner6} alt="VC Prime" fluid />
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>

                  <div
                    id="landingForm"
                    className="content-column appointment-form banner-form col-lg-4 col-md-12 col-sm-12 text-left px-4 py-0"
                  >
                    {/* <h1 className="fs-2 mb-4 mt-0">
                      Experience Premier Car Care Services in  <font color="#ed1c24">{selectedCity}</font>
                    </h1> */}

                    <div className="mb-3">
                      <font color="black" className="heading-font d-inline">
                        Experience Premier{" "}
                      </font>{" "}
                      <h1 className="d-inline">
                        {" "}
                        Car Care Services in{" "}
                        <font color="#ed1c24">{selectedCity}</font>
                      </h1>
                    </div>

                    {/* <div className="d-md-none px-2 mb-3">
                      <font color="black" className="heading-font-mobile d-inline">Experience Premier </font>
                      <h1 className="fs-2 d-inline"> Car Care Services in  <font color="#ed1c24">{selectedCity}</font></h1>
                    </div> */}

                    <div className="px-2">
                      <VehicleSelector navigate={true} />
                    </div>

                    <div className="d-none d-md-block">
                      <div className="row">
                        {!is_user_logged_in && (
                          <div
                            className={`form-group mt-2 col-sm-12 col-lg-12 col-md-12`}
                          >
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={homeMobile}
                              aria-describedby="inputGroupPrepend"
                              className="form-control border-0 phoneNo"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => updateMobileNum(e)}
                              ref={numberInput}
                              required
                            />
                          </div>
                        )}
                        <div
                          className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3"
                          onClick={serviceQuoteCall}
                        >
                          <button
                            className="theme-btn btn-style-nine getQuote"
                            type="submit"
                            name="submit-form"
                          >
                            <span className="txt">Check Price</span>
                          </button>
                        </div>

                        <div className="col-12">
                          <div className="text pb-0 mb-0 pt-2 mt-0">
                            Car Services -{" "}
                            <b>
                              <font color="#ed1c24" size="5">
                                10% Discount
                              </font>
                            </b>
                          </div>

                          <div className="text pb-0 mb-0 pt-2 mt-0">
                            Denting & Painting -{" "}
                            <b>
                              <font color="#ed1c24" size="5">
                                20% Discount
                              </font>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-md-none">
                      <div className="row clearfix px-3">
                        {!is_user_logged_in && (
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border col-lg-12 col-md-12`}
                          >
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={homeMobile}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => updateMobileNum(e)}
                              ref={numberInput}
                              required
                            />
                          </div>
                        )}

                        <div
                          className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3"
                          onClick={serviceQuoteCall}
                        >
                          <span className="theme-btn btn-style-nine getQuote">
                            <span className="txt">Check Price</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="image-column col-lg-8 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                    <div className="inner-column">
                      <div className="image">
                        <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                          }}
                          hasTrack={true}
                        >
                          {banners.map(item => (
                            <SplideSlide>
                            <img src={item} alt={`banner-${item}`} fluid />
                          </SplideSlide>
                          ))}
                        </Splide>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
